.form-container {
  position: relative;
  margin: 0 auto;
  padding: var(--size-60) var(--size-20);
  max-width: 60vw;
}

.form-container .error-message {
  color: red;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding-bottom: var(--size-30);
}

.form-container p {
  text-align: center;
}

.form {
  width: 100%;
}

.spinner-box {
  width: calc(250px + (300 - 250) * (100vw - 320px) / 1120);
  height: calc(250px + (300 - 250) * (100vw - 320px) / 1120);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}

.spinner-box.active {
  opacity: 1;
  visibility: visible;
}

.circle-border {
  width: calc(100px + (150 - 100) * (100vw - 320px) / 1120);
  height: calc(100px + (150 - 100) * (100vw - 320px) / 1120);
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(16, 16, 16);
  background: linear-gradient(
    0deg,
    rgba(16, 16, 16, 0.1) 33%,
    rgba(16, 16, 16, 1) 100%
  );
  -webkit-animation: spin 0.8s linear 0s infinite;
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: var(--off-white);
  border-radius: 50%;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.form-body.submitting {
  opacity: 0.2;
  transition: var(--std-trans);
}

.form .form-inner.form-inner-selects {
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.form .form-inner.form-inner-selects .form__group {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex: 0 0 33%;
  max-width: 33%;
  padding: 1rem;
  z-index: 2;
  transition: all var(--long-trans);
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.form .form-inner.done {
  opacity: 0.3;
  transition: opacity var(--std-trans);
}

.form .form-inner {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.form .form-inner .form__group__header {
  text-align: center;
  width: 100%;
  font-size: var(--h3);
  padding-top: calc(15px + (20 - 15) * (100vw - 320px) / 1120);
  max-width: 80%;
  margin: 0 auto;
}

.form .form-inner .form__group__selects-header {
  text-align: center;
  width: 100%;
  font-size: var(--h3);
  padding-top: calc(15px + (20 - 15) * (100vw - 320px) / 1120);
}

.form .form-inner textarea {
  resize: none;
  font-family: inherit;
}

.form .form-inner .form__group.large {
  flex: 0 0 100%;
}

.form .form-inner .form__group {
  position: relative;
  display: flex;
  flex: 0 0 80%;
  max-width: 80%;
  padding: 1rem;
  padding-bottom: 2rem;
  z-index: 2;
  transition: all var(--long-trans);
  margin: 0 auto;
  justify-content: space-around;
}

.form .form-inner .form__field-select {
  transition: all var(--long-trans);
  padding-bottom: var(--size-30);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.form .form-inner .form__field-select input[type="checkbox"] {
  position: absolute;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

input[type="checkbox"].error.form__field + label {
  color: red !important;
  font-weight: 500;
}

.form
  .form-inner
  .form__field-select
  input[type="checkbox"]:checked
  ~ .form__label-select:after {
  font-size: var(--fs-xs);
}

.form .form-inner .form__field-select .form__label-select {
  font-size: var(--fs-xs);
  color: var(--faded-black);
  letter-spacing: var(--medium-spacing);
  transition: all var(--std-trans);
  text-transform: uppercase;
  padding-bottom: calc(10px + (15 - 10) * (100vw - 320px) / 1120);
  font-weight: 500;
}

.form .form-inner .form__field-select .form__label-select:hover:after {
  cursor: pointer;
}

.form .form-inner .form__field-select .form__label-select:after {
  content: "X";
  margin: calc(10px + (12 - 10) * (100vw - 320px) / 1120) auto;
  height: calc(15px + (20 - 15) * (100vw - 320px) / 1120);
  width: calc(15px + (20 - 15) * (100vw - 320px) / 1120);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  font-size: 0;
  font-family: var(--alt-font);
  transition: var(--std-trans);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.form .form-inner .form__group.done .form__field,
.form .form-inner .form__group.done label,
.form .form-inner .form__field-select.done,
.form .form-inner .form__field-select.done label {
  opacity: 0.4;
}

.form .form-inner .error.form__field {
  border-color: red;
  opacity: 1 !important;
}

.form .form-inner .form__field {
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--faded-black);
  outline: 0;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form .form-inner .form__field::-webkit-input-placeholder {
  color: transparent;
}

.form .form-inner .form__field::-moz-placeholder {
  color: transparent;
}

.form .form-inner .form__field::placeholder {
  color: transparent;
}

.form .form-inner .form__field:placeholder-shown ~ .form__label {
  font-size: var(--fs-xs);
  cursor: text;
  top: 20px;
}

.form .form-inner .form__field + .form__label {
  position: absolute;
  top: -1rem;
  display: inline-block;
  transition: 0.2s;
  font-size: var(--fs-xs);
  color: var(--faded-black);
  letter-spacing: var(--medium-spacing);
  text-transform: capitalize;
  font-weight: 500;
}

.form .form-inner .form__field:focus {
  padding-bottom: 6px;
  border-width: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}

.form .form-inner .form__field:focus ~ .form__label {
  top: -0.8rem;
  font-size: var(--fs-xs);
  padding: 0 0.3em;
  color: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}

.form .acceptance {
  opacity: 0.2;
  padding: var(--size-30) 0;
}

.form .acceptance .form__label-select.acceptance-terms {
  font-size: calc(10px + (12 - 10) * (100vw - 320px) / 1120);
  padding-top: var(--size-30);
  width: 80%;
}

.form .ready.acceptance {
  opacity: 1;
}

.form__cancellation-notice {
  flex: 0 0 60%;
  max-width: 60%;
  margin: calc(0px - (20 - 10) * (100vw - 320px) / 1120) auto var(--h2) auto;
  font-size: calc(10px + (12 - 10) * (100vw - 320px) / 1120);
  padding: var(--size-12);
  color: var(--faded-black);
  letter-spacing: var(--medium-spacing);
  transition: all var(--std-trans);
  text-transform: uppercase;
}

.form .form-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: var(--size-30);
}

.form .form-recaptcha.error > div:first-child {
  border: 2px solid red;
  padding: 4px;
  border-radius: 4px;
}

.form .form-btn {
  z-index: 2;
  color: var(--faded-black);
  border: none;
  position: relative;
  margin: 0 auto 5vw auto;
  left: 50%;
  transform: translateX(-50%);
  transition: all var(--std-trans);
  margin-top: var(--size-30);
  display: inline-block;
  font-size: var(--article);
  padding: var(--size-12) calc(15px + (30 - 15) * (100vw - 320px) / 1120);
  padding-right: var(--size-60);
  letter-spacing: var(--medium-spacing);
  text-transform: uppercase;
  font-family: var(--main-font);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 600;
}

.form .form-btn .booking-arrow {
  transition: right 0.4s ease-in-out;
  position: absolute;
  right: var(--size-12);
  top: 50%;
  transform: translateY(-55%);
  font-size: calc(18px + (20 - 18) * (100vw - 320px) / 1120);
}

.form .form-btn:hover .booking-arrow {
  right: 5px;
}

.form .form-btn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.form .form-btn:active {
  outline: 0;
}

.form input,
.form textarea,
.form select {
  color: var(--faded-black);
  font-family: var(--main-font);
  line-height: 1.4;
  letter-spacing: 1.3px;
  font-size: var(--fs-xs);
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea {
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form textarea:-webkit-autofill,
.form textarea:-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus,
.form select:-webkit-autofill,
.form select:-webkit-autofill:hover,
.form select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px var(--off-white) inset;
  -webkit-text-fill-color: var(--faded-black);
  font-family: var(--main-font);
  line-height: 1.4;
  letter-spacing: 1.3px;
}

@media only screen and (max-width: 980px) {
  .form-container {
    z-index: 4;
  }
}

@media only screen and (max-width: 767px) {
  .form-container .form__label-select.acceptance-terms {
    max-width: 70%;
  }
}

@media only screen and (max-width: 479px) {
  .form-container {
    width: 65vw;
    padding: 20vw 0;
  }

  .form-container .form .form-inner .form__group {
    flex: 0 0 90%;
    max-width: 90%;
    padding-left: 0;
    padding-right: 0;
  }

  .form__cancellation-notice {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form .acceptance .form__label-select.acceptance-terms {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form .form-inner.form-inner-selects .form__group {
    height: auto;
  }
}
