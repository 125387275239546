.page-transition-container.animate {
  display: flex;
}

.page-transition-container {
  background-color: var(--white);
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  justify-content: center;
  align-items: center;
  display: none;
}

.page-transition-container .page-transition-svg {
  position: fixed;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: max-content;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-transition-container .page-transition-svg #pattern {
  width: 100%;
  height: 100%;
  color: white;
}

.page-transition-container .page-transition-svg #pattern rect {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.page-transition-container
  .page-transition-svg
  #pattern
  .page-transition-text-rect {
  width: 100%;
  height: 100%;
  color: var(--black);
  fill: currentColor;
}

.page-transition-container .page-transition-text {
  /* fill: url(#pattern); */
  fill: var(--faded-black);
  position: absolute;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(0, 85%);
  opacity: 1;
  animation: popUp 2s cubic-bezier(1, -0.03, 0.71, 1.02) forwards;
}

.page-transition-container .page-transition-text-wrapper {
  position: absolute;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: max-content;
  padding: var(--size-20);
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-transition-container .page-transition-text-title {
  color: var(--faded-black);
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  transform: translate(0, 150%);
  opacity: 1;
  animation: popUp 2s cubic-bezier(1, -0.03, 0.71, 1.02) forwards;
}

@keyframes popUp {
  0% {
    transform: translate(0, 150%);
    opacity: 1;
  }

  30%,
  65% {
    transform: translate(0, 0%);
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translate(85%, 0%);
    opacity: 0;
  }
}

.page-transition-container .page-transition-arrow-keys {
  position: absolute;
  font-size: 2rem;
  line-height: 1.65rem;
  font-weight: bold;
  bottom: calc(80px + (100 - 80) * (100vw - 320px) / 1120);
  left: 50%;
  transform: translateX(-50%);
  color: var(--black);
  display: flex;
  flex-flow: row wrap;
}

.page-transition-container .page-transition-arrow-keys-title {
  font-size: 50%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  opacity: 0;
  animation: reveal 0.2s ease-in-out forwards;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.page-transition-container .page-transition-arrow-keys-row {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key,
.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key-up {
  border: 2px solid var(--black);
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px;
}
.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key-up {
  left: 50%;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key,
.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key-up {
  transform: translateY(10px);
  animation: fadeUp 0.6s ease-in-out forwards;
  opacity: 0;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key:first-of-type {
  animation-delay: 0.1s;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key:nth-of-type(2) {
  animation-delay: 0.2s;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key:nth-of-type(3) {
  animation-delay: 0.3s;
}

.page-transition-container
  .page-transition-arrow-keys-row
  .page-transition-arrow-key:nth-of-type(4) {
  animation-delay: 0.4s;
}

@keyframes fadeUp {
  0% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 980px) {
  .page-transition-container .page-transition-arrow-keys {
    display: none;
  }
}
