.loading-container {
  background-color: var(--white);
  z-index: 999;
  position: fixed;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  display: block;
}

.loading-container .loading-svg {
  position: fixed;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: max-content;
}

.loading-container .loading-svg #pattern .loading-text-rect {
  width: 100%;
  height: 100%;
  color: var(--black);
  fill: currentColor;
}

.loading-container .loading-text {
  fill: url(#pattern);
  position: absolute;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  top: 50%;
  left: 50%;
  animation: pulsate 2s ease-in-out infinite;
}

.loading-container .loading-arrow-keys {
  position: absolute;
  font-size: 2rem;
  line-height: 1.65rem;
  font-weight: bold;
  bottom: calc(80px + (100 - 80) * (100vw - 320px) / 1120);
  left: 50%;
  transform: translateX(-50%);
  color: var(--black);
  display: flex;
  flex-flow: row wrap;
  animation: pulsate 2s ease-in-out infinite;
}

.loading-container .loading-arrow-keys-title {
  font-size: 50%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}

.loading-container .loading-arrow-keys-row {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.loading-container .loading-arrow-keys-row .loading-arrow-key,
.loading-container .loading-arrow-keys-row .loading-arrow-key-up {
  border: 2px solid var(--black);
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px;
}
.loading-container .loading-arrow-keys-row .loading-arrow-key-up {
  left: 50%;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 980px) {
  .loading-container .loading-arrow-keys {
    display: none;
  }
}
