.studio-page-section {
  min-height: -webkit-fill-available;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.studio-page .studio-page-section .studio-page-content-container:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  z-index: -1;
  pointer-events: none;
}

.studio-page .studio-page-section:first-of-type :is(h1, h2, h3, h4, h5, h6) {
  transition-delay: 1.8s;
}

.studio-page .studio-page-section:first-of-type .studio-info {
  transition-delay: 2s;
}

.studio-page-section .page-title {
  text-align: center;
  position: absolute;
  width: max-content;
  top: var(--size-30);
  left: 50%;
  transform: translateX(-50%);
}

.studio-page
  .studio-page-section:first-of-type
  .btn-container.fadeIn
  .studio-btn {
  transition: opacity var(--std-trans) 2.1s, transform var(--std-trans) 2.1s,
    background-color var(--std-trans);
}

.studio-page .studio-page-section .studio-page-content-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  pointer-events: none;
}

.studio-page .studio-page-text-container {
  text-transform: uppercase;
  max-width: 95%;
  margin: 0 auto;
  z-index: 2;
}

.studio-page header:not(.page-title) {
  margin-bottom: var(--size-30);
  font-size: var(--h3);
  writing-mode: tb;
}

.studio-page .btn-container {
  padding-top: calc(50px + (70 - 50) * (100vw - 320px) / 1120);
  max-width: calc(200px + (230 - 200) * (100vw - 320px) / 1120);
  z-index: 3;
  display: flex;
  justify-content: center;
  max-width: fit-content;
}

.studio-page .btn-container .studio-btn {
  transition: background-color var(--std-trans);
  display: inline-block;
  font-size: var(--article);
  padding: var(--size-12) calc(15px + (30 - 15) * (100vw - 320px) / 1120);
  letter-spacing: var(--medium-spacing);
  font-family: var(--main-font);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 600;
  text-transform: none;
}

.studio-page .btn-container .studio-btn:hover {
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.studio-page .studio-page-section:last-of-type .angle-down {
  transform: rotate(0deg);
  animation: bounceUp 2s infinite;
}

.studio-page .studio-page-section:last-of-type .angle-down:hover {
  opacity: 0.6;
  animation-play-state: paused;
}

@media only screen and (min-width: 980px) {
  .studio-page .studio-info {
    max-width: 40vw;
  }
}

@media only screen and (max-width: 980px) and (orientation: landscape) {
  .studio-page-section {
    position: relative;
  }

  .studio-page-section h3 {
    padding-bottom: var(--size-20);
  }

  .studio-page-content-container {
    padding-top: var(--size-60);
  }

  .studio-page-text-container .studio-info {
    font-size: var(--size-12);
  }

  .studio-page header {
    writing-mode: unset;
    margin-bottom: 0;
  }

  .studio-page .btn-container {
    position: absolute;
    right: var(--size-20);
    bottom: var(--size-40);
  }

  .studio-page .btn-container .studio-btn {
    padding: 15px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .studio-page-text-container .studio-info {
    max-width: 80vw;
  }
}
