/* raleway-regular - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-600 - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-600.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-700 - latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/raleway-v28-latin/raleway-v28-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-500.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-500.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../assets/fonts/roboto-v30-latin/roboto-v30-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  /* Colors */
  --faded-black: #101010;
  --faded-black-dark: #0c0c0c;
  --faded-black-70: rgb(22, 22, 22);
  --faded-black-50: rgb(22, 22, 22, 0.5);
  --grey: #c7c7c7;
  --dark-grey: #424242;
  --off-white: #fffdf7;
  --off-white-70: rgba(255, 253, 247, 0.7);
  --black: #000;
  --white: #fff;
  --def-background: linear-gradient(
    90deg,
    rgb(29, 29, 29) 18%,
    rgba(0, 0, 0, 1) 100%
  ) !important;

  /* Transitions */
  --std-trans: 1.1s cubic-bezier(0.23, 1, 0.32, 1);
  --long-trans: 1.4s cubic-bezier(0.23, 1, 0.32, 1);

  /* Typography */
  /* --calc-size: (100vw - 320px) / 1120; */
  --h2: calc(30px + (80 - 30) * (100vw - 320px) / 1120);
  --h3: calc(18px + (30 - 18) * (100vw - 320px) / 1120);
  --h4: calc(16px + (25 - 16) * (100vw - 320px) / 1120);
  --article: calc(14px + (18 - 14) * (100vw - 320px) / 1120);
  --fs-small: calc(16px + (18 - 16) * (100vw - 320px) / 1120);
  --fs-xs: calc(12px + (16 - 12) * (100vw - 320px) / 1120);
  --medium-spacing: 0.08rem;

  --main-font: "Roboto", sans-serif;
  --heading-font: "Raleway", sans-serif;

  /* Sizes */
  --size-80: calc(60px + (80 - 60) * (100vw - 320px) / 1120);
  --size-60: calc(40px + (60 - 40) * (100vw - 320px) / 1120);
  --size-50: calc(30px + (50 - 30) * (100vw - 320px) / 1120);
  --size-40: calc(20px + (40 - 20) * (100vw - 320px) / 1120);
  --size-30: calc(20px + (30 - 20) * (100vw - 320px) / 1120);
  --size-20: calc(10px + (20 - 10) * (100vw - 320px) / 1120);
  --size-15: calc(10px + (15 - 10) * (100vw - 320px) / 1120);
  --size-12: calc(8px + (12 - 8) * (100vw - 320px) / 1120);
  --size-10: calc(8px + (10 - 8) * (100vw - 320px) / 1120);
  --size-5: calc(3px + (5 - 3) * (100vw - 320px) / 1120);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--main-font);
}

body.fixed {
  overflow: hidden;
}

body.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body.loading nav,
body.loading nav .hamburger,
body.loading #fp-nav {
  opacity: 0 !important;
  visibility: hidden !important;
}

body nav,
body nav .hamburger,
body #fp-nav {
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity var(--std-trans);
  transition-delay: 2s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: var(--medium-spacing);
  padding-bottom: var(--size-30);
  word-break: break-word;
  hyphens: auto;
}

h2,
.h2 {
  padding-bottom: var(--size-10);
  font-size: var(--h2);
}

h3,
.h3 {
  font-size: var(--h3);
}

.p-h1,
.p-h2,
.p-h3,
.p-h4,
.p-h5,
.p-h6 {
  font-size: var(--article);
}

a {
  color: inherit;
  text-decoration: none;
  font-family: var(--main-font);
  line-height: 1.4;
  letter-spacing: 1.3px;
  transition: opacity var(--std-trans);
}

a:hover:not(.main-page-cta-btn-link) {
  opacity: 0.7;
}

p {
  font-family: var(--main-font);
  line-height: 1.4;
  letter-spacing: 1.3px;
  padding-bottom: var(--size-20);
  font-size: var(--article);
}

p:last-child {
  padding-bottom: 0;
}

#fp-nav {
  top: var(--size-30) !important;
  right: var(--size-30) !important;
  transform: none !important;
  width: var(--size-60) !important;
}

#fp-nav ul {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);
  width: 100%;
  align-items: flex-end;
}

#fp-nav ul li {
  width: 100% !important;
  display: inline-flex !important;
  align-items: center;
  margin: 0 !important;
  height: var(--size-15) !important;
  width: var(--size-15) !important;
}

#fp-nav ul li a span {
  background: var(--off-white) !important;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

#fp-nav ul li .fp-tooltip {
  color: var(--off-white) !important;
  font-family: var(--main-font) !important;
  font-size: calc(10px + (12 - 10) * (100vw - 320px) / 1120) !important;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 1.3px;
  font-weight: 600;
  top: 0 !important;
}

@keyframes bounceUp {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  57% {
    transform: translateY(-2px);
  }
  64% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 980px) {
  .main-page-section header,
  .main-page-section article,
  .main-page-section .main-page-cta-btn-link,
  .studio-page header,
  .studio-page article,
  .studio-page .btn-container,
  .studio-sigle-main .text-wrapper,
  .studio-sigle-main .studio-single-socials-container {
    overflow: hidden;
  }
}

.main-page-section header :is(h1, h2, h3, h4, h5, h6),
.main-page-section article :is(p),
.main-page-section .main-page-cta-btn-link .main-page-cta-btn,
.main-page-section:last-of-type .main-page-text-container-paragraph,
.studio-page header :is(h1, h2, h3, h4, h5, h6),
.studio-page article .studio-info,
.studio-page .btn-container .studio-btn,
.studio-sigle-main .text-wrapper .fade,
.studio-sigle-main .text-wrapper .booking-link,
.studio-sigle-main .studio-single-socials-container .studio-single-socials {
  transform: translateY(150%);
  opacity: 0;
  transition: opacity var(--std-trans), transform var(--std-trans);
}

.main-page-section article :is(p),
.studio-sigle-main .text-wrapper .fade:is(p),
.main-page-section:last-of-type .main-page-text-container-paragraph {
  transition-delay: 0.2s;
}

.main-page-section .studio-single-socials {
  opacity: 0;
  transition: opacity var(--std-trans);
}

.main-page-section header.fadeIn :is(h1, h2, h3, h4, h5, h6),
.main-page-section:not(:last-of-type) article.fadeIn :is(p),
.main-page-section .main-page-cta-btn-link.fadeIn .main-page-cta-btn,
.main-page-section:last-of-type
  article.fadeIn
  .main-page-text-container-paragraph,
.studio-page header.fadeIn :is(h1, h2, h3, h4, h5, h6),
.studio-page article.fadeIn .studio-info,
.studio-page .btn-container.fadeIn .studio-btn,
.studio-sigle-main .text-wrapper.fadeIn .fade,
.studio-sigle-main .text-wrapper.fadeIn .booking-link,
.studio-sigle-main
  .studio-single-socials-container.fadeIn
  .studio-single-socials {
  transform: translateY(0px);
  opacity: 1;
}

.main-page-section .studio-single-socials.fadeIn {
  opacity: 1;
}

.main-page-section:not(:first-of-type)
  .main-page-cta-btn-link.fadeIn
  .main-page-cta-btn,
.studio-page .btn-container.fadeIn .studio-btn,
.studio-sigle-main .text-wrapper.button-wrapper.fadeIn .booking-link {
  transition: opacity var(--std-trans) 0.4s, transform var(--std-trans) 0.4s,
    background-color var(--std-trans);
}

.studio-page .btn-container.fadeIn,
.studio-sigle-main .text-wrapper.button-wrapper.fadeIn,
.main-page-section .main-page-cta-btn-link.fadeIn {
  overflow: visible;
}

@media only screen and (max-width: 980px) {
  .main-page-section header :is(h1),
  .studio-page header :is(h1),
  .main-page-section:first-of-type .main-page-cta-btn-link .main-page-cta-btn {
    opacity: 1;
    transform: none;
  }
}
