.studio-sigle-main {
  background: var(--white);
}

.studio-sigle-main .studio-single-hero-sec {
  min-height: 100lvh;
  display: flex;
  align-items: center;
  padding-top: var(--size-50);
}

.studio-sigle-main .studio-single-hero-sec .studio-single-hero-arrow {
  position: absolute;
  bottom: var(--size-20);
  font-size: var(--size-50);
  font-weight: 100;
  left: 12vw;
}

.studio-sigle-main .studio-single-hero-sec .studio-single-hero-header {
  flex: 0 0 40%;
  max-width: 40%;
  margin-left: 12vw;
}

.studio-sigle-main
  .studio-single-hero-sec
  .studio-single-hero-header
  .studio-single-hero-header-title {
  padding-bottom: calc(20 + (30 - 20) * (100vw - 320px) / 1120);
  font-size: var(--size-80);
  font-family: var(--ff-base);
}

.studio-sigle-main .studio-single-hero-sec .studio-number {
  font-size: calc(150px + (350 - 150) * (100vw - 320px) / 1120);
  display: block;
  opacity: 0.3;
  position: absolute;
  top: 30%;
  left: 70%;
}

.studio-single-slider-sec {
  min-height: 70vh;
  padding: calc(40px + (60 - 40) * (100vw - 320px) / 1120) 0
    calc(60px + (120 - 60) * (100vw - 320px) / 1120) 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.studio-single-slider-sec .text-container {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 6vw 4vw 6vw 8vw;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.studio-single-slider-sec .slick-slider {
  flex: 0 0 50%;
  max-width: 50%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.studio-single-slider-sec button.slick-next,
.studio-single-slider-sec button.slick-prev {
  left: 0;
  z-index: 2;
  display: none !important;
}

.studio-single-slider-sec button.slick-next {
  right: 0;
  left: auto;
}

.studio-single-slider-sec .slick-slide {
  cursor: grab;
}

.studio-single-slider-sec .slick-slide:active {
  cursor: grabbing;
}

.studio-single-slider-sec .slick-slider .slick-dots button:before {
  transition: all 0.2s ease-in-out;
}

.studio-single-slider-sec .slick-list,
.studio-single-slider-sec .slick-track,
.studio-single-slider-sec .slick-slide,
.studio-single-slider-sec .slick-slide > div,
.studio-single-slider-sec .slick-slide-inner,
.studio-single-slider-sec .slick-slide-inner img {
  height: 100%;
  max-height: 100vh;
}

.studio-single-slider-sec .slick-slide-inner img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.studio-single-image-grid-sec {
  text-align: center;
  padding: 5vw 12vw;
}

.studio-single-image-grid-sec p {
  max-width: 70%;
  margin: 0 auto;
}

.studio-single-image-grid-sec .studio-single-photo-grid-container {
  display: flex;
  flex-flow: row wrap;
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row {
  flex: 0 0 33%;
  max-width: 33%;
  display: flex;
  flex-flow: column;
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row
  .photo-grid-img-container {
  padding: calc(5px + (10 - 5) * (100vw - 320px) / 1120)
    calc(5px + (10 - 5) * (100vw - 320px) / 1120);
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row
  .photo-grid-img-inner-container {
  position: relative;
  padding-top: 140%;
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row
  .photo-grid-img-inner-container
  img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row
  .photo-meta {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transition: var(--long-trans);
  background: var(--faded-black);
  width: 100%;
  height: 0;
  visibility: hidden;
}

.studio-single-image-grid-sec
  .studio-single-photo-grid-container
  .photo-grid-row
  .photo-meta
  p {
  opacity: 0;
  transition: var(--std-trans);
  color: var(--off-white);
  padding-bottom: calc(5px + (10 - 5) * (100vw - 320px) / 1120);
  visibility: hidden;
}

/*.photo-grid-img-inner-container:hover .photo-meta {
  height: 100% !important;
  visibility: visible !important;
}

.photo-grid-img-inner-container:hover .photo-meta p {
  -webkit-animation: fadeIn 0.6s ease-in-out 0.1s forwards;
  animation: fadeIn 0.6s ease-in-out 0.1s forwards;
}*/

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.studio-single-floorplan-sec {
  min-height: 50vw;
  padding: calc(40px + (60 - 40) * (100vw - 320px) / 1120) 0
    calc(60px + (120 - 60) * (100vw - 320px) / 1120) 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.studio-single-floorplan-sec .text-container {
  flex: 0 0 45%;
  max-width: 45%;
  padding: 6vw 4vw;
  background: var(--faded-black);
  color: var(--off-white);
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.studio-single-floorplan-sec .floorplan-img-container {
  position: relative;
  flex: 0 0 55%;
  max-width: 55%;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.studio-single-floorplan-sec .floorplan-img-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: contain;
  object-position: bottom;
  height: 100%;
  width: 100%;
}

.studio-single-contact-section {
  display: flex;
  padding: calc(40px + (60 - 40) * (100vw - 320px) / 1120)
    calc(20px + (40 - 20) * (100vw - 320px) / 1120);
  align-items: center;
  flex-flow: column;
  text-align: center;
}

.studio-single-contact-section .booking-link {
  position: relative;
  padding: calc(10px + (15 - 10) * (100vw - 320px) / 1120)
    calc(10px + (15 - 10) * (100vw - 320px) / 1120);
  display: flex;
  align-items: center;
  font-family: var(--ff-base);
  margin: calc(20px + (30 - 20) * (100vw - 320px) / 1120) auto;
  background: rgba(255, 255, 255, 0.2);
  transition: all var(--std-trans);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 500;
}

.studio-single-contact-section .booking-link .booking-arrow {
  transition: right 0.4s ease-in-out;
  position: absolute;
  right: calc(8px + (10 - 8) * (100vw - 320px) / 1120);
}

.studio-sigle-main .text-wrapper.button-wrapper.fadeIn .booking-link:hover {
  opacity: 0.7;
}

.studio-single-contact-section .booking-link:hover .booking-arrow {
  right: calc(3px + (5 - 3) * (100vw - 320px) / 1120);
}

.studio-single-socials-container {
  width: 70%;
}

.studio-single-socials-container .studio-single-socials {
  justify-content: center;
  align-items: center;
  padding-top: calc(40px + (60 - 40) * (100vw - 320px) / 1120);
  display: flex;
  padding-right: var(--size-20);
}

.studio-single-socials-container .studio-single-socials p {
  padding-bottom: 0;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  text-align: end;
  font-family: var(--main-font);
  font-size: var(--h3);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: var(--medium-spacing);
}

.studio-single-socials-container .studio-single-socials .line {
  width: 33.333%;
  font-size: var(--h3);
}

.studio-single-socials-container
  .studio-single-socials
  .studio-single-socials-icons {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.studio-single-socials-container
  .studio-single-socials
  .studio-single-socials-icons
  a {
  padding: var(--size-12);
  font-size: var(--h3);
}

@media only screen and (max-width: 980px) {
  .studio-sigle-main .studio-single-slider-sec,
  .studio-sigle-main .studio-single-image-grid-sec,
  .studio-sigle-main .studio-single-floorplan-sec,
  .studio-sigle-main .studio-single-contact-section {
    padding-top: 10%;
  }

  .studio-sigle-main .studio-single-slider-sec .text-container,
  .studio-sigle-main .studio-single-slider-sec .slick-slider {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studio-sigle-main .studio-single-slider-sec .slick-list,
  .studio-sigle-main .studio-single-slider-sec .slick-track,
  .studio-sigle-main .studio-single-slider-sec .slick-slide,
  .studio-sigle-main .studio-single-slider-sec .slick-slide > div,
  .studio-sigle-main .studio-single-slider-sec .slick-slide-inner,
  .studio-sigle-main .studio-single-slider-sec .slick-slide-inner img {
    height: 60vw;
  }

  .studio-sigle-main
    .studio-single-image-grid-sec
    .studio-single-photo-grid-container
    .photo-grid-row {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studio-sigle-main .studio-single-floorplan-sec .text-container,
  .studio-sigle-main .studio-single-floorplan-sec .floorplan-img-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studio-sigle-main .studio-single-floorplan-sec .text-container {
    padding: 20vw 5vw;
  }

  .studio-sigle-main .studio-single-floorplan-sec .text-container h2 {
    padding-top: 30px;
  }

  .studio-sigle-main .studio-single-floorplan-sec .floorplan-img-container {
    padding-bottom: 50%;
  }

  .studio-sigle-main
    .studio-single-contact-section
    .studio-single-socials-container,
  .studio-sigle-main .studio-single-contact-section .studio-single-socials {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) and (orientation: landscape) {
  .studio-sigle-main .studio-single-hero-sec .studio-single-hero-arrow {
    bottom: -12vw;
  }
}

@media only screen and (max-width: 767px) {
  .studio-sigle-main .studio-single-hero-sec .studio-single-hero-header {
    flex: 0 0 68%;
    max-width: 68%;
  }

  .studio-sigle-main .studio-single-image-grid-sec p {
    max-width: none;
    padding-bottom: var(--size-30);
  }
  .studio-sigle-main .studio-single-slider-sec {
    flex-flow: column;
  }

  .studio-sigle-main .studio-single-slider-sec .text-container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 20%;
    padding-bottom: 0;
  }

  .studio-sigle-main .studio-single-slider-sec .slick-slider {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: var(--size-30);
  }

  .studio-sigle-main .studio-single-floorplan-sec .floorplan-img-container {
    padding-bottom: 70%;
  }

  .studio-sigle-main .studio-single-image-grid-sec,
  .studio-sigle-main .studio-single-contact-section,
  .studio-sigle-main .studio-single-floorplan-sec {
    padding-top: 20%;
  }

  .studio-sigle-main
    .studio-single-image-grid-sec
    .studio-single-photo-grid-container
    .photo-grid-row {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .studio-sigle-main .studio-single-socials {
    padding-right: 0;
  }

  .studio-single-socials-container .studio-single-socials p {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .studio-single-socials-container .studio-single-socials .line {
    width: 10%;
  }
}

@media only screen and (max-width: 479px) {
  .studio-single-socials-container .studio-single-socials {
    flex-direction: column;
  }

  .studio-single-socials-container
    .studio-single-socials
    .studio-single-socials-icons,
  .studio-single-socials-container .studio-single-socials .line {
    margin-top: var(--size-30);
  }
}
