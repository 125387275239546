.primary-nav-container {
  position: fixed;
  z-index: 9999;
  width: 100vw;
}

.primary-nav-container .primary-nav ul {
  list-style-type: none;
}

.primary-nav-container .primary-nav ul .navbar-item {
  position: absolute;
  opacity: 1;
  top: 50vh;
  padding: calc(8px + (10 - 8) * (100vw - 320px) / 1120)
    calc(15px + (25 - 15) * (100vw - 320px) / 1120);
  z-index: 99;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  font-size: var(--h5);
  transition: var(--std-trans);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.primary-nav-container .primary-nav ul .navbar-item:hover {
  background: rgba(0, 0, 0, 0.2);
}

.primary-nav-container .primary-nav ul .navbar-right-item:hover {
  padding-right: var(--size-15);
}

.primary-nav-container .primary-nav ul .navbar-left-item:hover {
  padding-left: var(--size-15);
}

.primary-nav-container .primary-nav ul .navbar-item:hover .angle-right {
  margin-left: var(--size-20);
}

.primary-nav-container .primary-nav ul .navbar-item:hover .angle-left {
  margin-right: var(--size-20);
}

.primary-nav-container .primary-nav ul .navbar-left-item {
  left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.primary-nav-container .primary-nav ul .navbar-right-item {
  right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.primary-nav-container .primary-nav ul li {
  display: flex;
  align-items: center;
}

.primary-nav-container.light a {
  color: var(--off-white);
  letter-spacing: var(--medium-spacing);
  font-family: var(--main-font);
  transition: var(--std-trans);
  font-size: calc(10px + (12 - 10) * (100vw - 320px) / 1120);
  letter-spacing: 1.4px;
}

.primary-nav-container .angle {
  transition: var(--std-trans);
}

.primary-nav-container.light .angle {
  color: var(--off-white);
  font-size: 1em;
}

.primary-nav-container.dark a {
  color: var(--faded-black);
  letter-spacing: var(--medium-spacing);
  font-family: var(--main-font);
  transition: var(--std-trans);
  font-size: calc(10px + (12 - 10) * (100vw - 320px) / 1120);
  letter-spacing: 1.4px;
}

.primary-nav-container.dark .angle {
  color: var(--faded-black);
  font-size: 1em;
}

/* Navbar Curtain */

.primary-nav-container-curtain {
  position: fixed;
  top: var(--size-30);
  left: var(--size-30);
  z-index: 2;
  z-index: 9999;
  transition: var(--long-trans);
}

.primary-nav-container-curtain .hamburger {
  z-index: 999;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.primary-nav-container-curtain .hamburger,
.primary-nav-container-curtain .hamburger:before,
.primary-nav-container-curtain .hamburger:after {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 2px;
  vertical-align: middle;
}

.primary-nav-container-curtain .hamburger:before,
.primary-nav-container-curtain .hamburger:after {
  transition: background-color 0.2s ease-in-out 0.2s;
}

.primary-nav-container-curtain.dark .hamburger,
.primary-nav-container-curtain.dark .hamburger:before,
.primary-nav-container-curtain.dark .hamburger:after,
.primary-nav-container-curtain.light .hamburger-icon.open .hamburger:before,
.primary-nav-container-curtain.light .hamburger-icon.open .hamburger:after {
  background: var(--faded-black);
}

.primary-nav-container-curtain.light .hamburger,
.primary-nav-container-curtain.light .hamburger:before,
.primary-nav-container-curtain.light .hamburger:after {
  background: var(--white);
}

.primary-nav-container-curtain .hamburger:before,
.primary-nav-container-curtain .hamburger:after {
  content: "";
  position: absolute;
  display: block;
  transition: all 0.2s ease 0.2s;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.primary-nav-container-curtain .hamburger:before {
  top: -7px;
}

.primary-nav-container-curtain .hamburger:after {
  top: 7px;
}

.primary-nav-container-curtain .hamburger-icon,
.primary-nav-container-curtain .hamburger-icon .hamburger {
  transition: background-color 0s ease 0.2s;
}

.primary-nav-container-curtain .hamburger-icon.open .hamburger {
  background: none;
}

.primary-nav-container-curtain .hamburger-icon.open .hamburger:before,
.primary-nav-container-curtain .hamburger-icon.open .hamburger:after {
  top: 0;
  transition: top 0.2s ease, transform 0.2s ease 0.2s,
    background-color 0.2s ease;
}

.primary-nav-container-curtain .hamburger-icon.open .hamburger:before {
  transform: rotate(45deg);
}

.primary-nav-container-curtain .hamburger-icon.open .hamburger:after {
  transform: rotate(-45deg);
}

.primary-nav-container-curtain .hamburger-icon:hover {
  cursor: pointer;
}

.primary-nav-container-curtain .hamburger-icon:hover .hamburger:before {
  width: 90%;
}

.primary-nav-container-curtain .hamburger-icon:hover .hamburger:after {
  width: 70%;
}

.primary-nav-container-curtain .hamburger-icon.open:hover .hamburger:before,
.primary-nav-container-curtain .hamburger-icon.open:hover .hamburger:after {
  width: 100%;
  background: var(--faded-black-50);
}

.primary-nav-container-curtain .overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: all var(--long-trans) 0.3s;
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%),
    0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%),
    0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);
}

.primary-nav-container-curtain .overlay:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--faded-black);
  z-index: -1;
}

.primary-nav-container-curtain .overlay.open {
  width: 30vw;
  transition-delay: 0s;
}

.primary-nav-container-curtain .overlay-content {
  padding: calc(60px + (80 - 60) * (100vw - 320px) / 1120) 0;
  display: flex;
  flex-direction: column;
  width: 0;
  height: auto;
  min-height: 100%;
  background: var(--white);
  transition: all var(--std-trans) 0.2s;
}

.primary-nav-container-curtain .overlay.open .overlay-content {
  width: 100%;
  padding: calc(60px + (80 - 60) * (100vw - 320px) / 1120)
    calc(20px + (40 - 20) * (100vw - 320px) / 1120);
}

.primary-nav-container-curtain .overlay-content .link-wrapper {
  overflow: hidden;
  display: block;
  width: 100%;
  height: fit-content;
}

.primary-nav-container-curtain .overlay-content a {
  display: inline-block;
  padding: var(--size-10);
  text-decoration: none;
  font-size: var(--h3);
  font-family: var(--ff-base);
  color: var(--faded-black);
  font-weight: 700;
  letter-spacing: var(--medium-spacing);
  text-transform: uppercase;
  text-align: center;
  padding-bottom: var(--size-10);
  transition: transform var(--std-trans), color var(--std-trans),
    padding var(--std-trans), opacity 0.2s 0.2s;
  opacity: 0;
}

.primary-nav-container-curtain .overlay-content .menu-item,
.primary-nav-container-curtain .overlay-content .submenu-item {
  transform: translateY(150%);
  opacity: 1;
}

.primary-nav-container-curtain .overlay.open .menu-item,
.primary-nav-container-curtain .overlay.open .submenu-item {
  transition: transform var(--std-trans) 0.4s, color var(--std-trans),
    padding var(--std-trans), opacity 0.2s 0s;
  transform: translateY(0);
}

.primary-nav-container-curtain .overlay-content .submenu-items {
  margin-top: var(--size-30);
  width: 100%;
}

.primary-nav-container-curtain .overlay-content a.submenu-item {
  font-size: var(--fs-small);
}

.primary-nav-container-curtain .overlay-content a.submenu-item:before {
  content: "";
  display: block;
  height: 3px;
  width: var(--size-12);
  background: var(--faded-black);
  margin-right: var(--size-12);
  transition: all var(--std-trans);
}

.primary-nav-container-curtain .overlay-content .menu-item.active {
  padding-left: var(--size-15);
  text-decoration: underline;
  text-underline-offset: var(--size-10);
}

.primary-nav-container-curtain .overlay-content .menu-item.active:hover {
  padding-left: var(--size-20);
}

.primary-nav-container-curtain .overlay-content .submenu-item.active:before {
  width: var(--size-30);
}

.primary-nav-container-curtain
  .overlay-content
  .submenu-item.active:hover:before {
  width: var(--size-12);
}

.primary-nav-container-curtain .overlay-content .submenu-item:hover:before {
  width: var(--size-30);
}

.primary-nav-container-curtain .overlay-content .menu-item:hover {
  padding-left: var(--size-15);
}

.primary-nav-container-curtain .overlay-content a:hover,
.primary-nav-container-curtain .overlay-content a:focus {
  color: var(--faded-black-50);
}

.primary-nav-container-curtain .nav-socials-container p,
.primary-nav-container-curtain .nav-socials-container p + svg {
  display: none;
}

.primary-nav-container-curtain .nav-socials-container,
.primary-nav-container-curtain .nav-contacts-container {
  margin-top: var(--size-60);
  width: 100%;
}

.primary-nav-container-curtain .nav-socials-container,
.primary-nav-container-curtain .nav-contact {
  overflow: hidden;
  max-width: fit-content;
}

.primary-nav-container-curtain .overlay.open .nav-socials-container a,
.primary-nav-container-curtain .overlay.open .nav-contact a {
  transition: transform var(--std-trans) 0.4s, opacity 0.2s 0s,
    color var(--std-trans);
  transform: translateY(0);
  opacity: 1;
}

.primary-nav-container-curtain .nav-contact a,
.primary-nav-container-curtain .nav-socials-container a {
  transition: transform var(--std-trans), opacity 0.2s 0.2s,
    color var(--std-trans);
  transform: translateY(180%);
  opacity: 0;
}

.primary-nav-container-curtain .nav-socials-container a {
  font-size: var(--size-40);
  margin-right: var(--size-15);
}

.primary-nav-container-curtain .nav-contacts-container {
  display: flex;
  flex-direction: column;
}

.primary-nav-container-curtain .overlay-content .nav-contact a {
  display: inline-flex;
  align-items: center;
  font-size: var(--size-15);
  gap: var(--size-10);
  padding: 0 0 var(--size-10);
}

/* Angles */

.angle-down.dark {
  color: var(--faded-black);
}

.angle-down {
  position: absolute;
  bottom: 0;
  width: auto;
  left: 50%;
  transform: rotate(180deg);
  margin-bottom: 10px;
  z-index: 3;
  animation: bounce 2s infinite;
  cursor: pointer;
  transition: all var(--std-trans);
}

.angle-down:hover {
  opacity: 0.6;
  animation-play-state: paused;
}

@keyframes bounce {
  0% {
    transform: rotate(180deg) translateY(0);
  }
  10% {
    transform: rotate(180deg) translateY(0);
  }
  30% {
    transform: rotate(180deg) translateY(20px);
  }
  50% {
    transform: rotate(180deg) translateY(0);
  }
  57% {
    transform: rotate(180deg) translateY(2px);
  }
  64% {
    transform: rotate(180deg) translateY(0);
  }
  100% {
    transform: rotate(180deg) translateY(0);
  }
}

.angle-right {
  transform: rotate(90deg);
  margin-left: var(--size-12);
}

.angle-left {
  transform: rotate(-90deg);
  margin-right: var(--size-12);
}

@media only screen and (max-width: 980px) {
  .primary-nav-container {
    display: none;
  }

  .primary-nav-container-curtain .overlay.open {
    width: 60vw;
  }

  .primary-nav-container .primary-nav ul .navbar-item.dark,
  .primary-nav-container .primary-nav ul .navbar-item.dark .angle {
    color: var(--faded-black);
  }
}

@media only screen and (max-width: 980px) and (orientation: landscape) {
  .primary-nav-container-curtain .overlay-content {
    padding: 6%;
    align-items: flex-start;
  }

  .primary-nav-container .primary-nav ul .navbar-item {
    padding: 0 var(--size-40);
  }

  .primary-nav-container-curtain {
    top: var(--size-30);
    left: var(--size-30);
    display: inline-block;
    width: auto;
  }
}

@media only screen and (max-width: 479px) {
  .primary-nav-container-curtain .overlay.open {
    width: 100vw;
  }

  .primary-nav-container-curtain .overlay-content a,
  .primary-nav-container-curtain .overlay-content a:not(.submenu-item) {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: start;
  }

  .primary-nav-container .primary-nav ul .navbar-item {
    top: 83vh;
    padding: 0 15px;
  }

  .angle-down {
    bottom: 2vh;
  }
}
