.main-page-section {
  color: var(--off-white);
  min-height: -webkit-fill-available;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  background: var(--faded-black);
}

.main-page-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-page-section .main-page-content-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  pointer-events: none;
}

.main-page-section .main-page-content-container:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.main-page-section header {
  display: flex;
  justify-content: center;
  flex: 0 0 100%;
  max-width: 100%;
}

.main-page-section:first-of-type header h1 {
  font-size: var(--h2);
  margin-top: var(--article);
  transition-delay: 2s;
}

.main-page-section:first-of-type .main-page-cta-btn-link .main-page-cta-btn {
  transition: opacity var(--std-trans) 2.1s, transform var(--std-trans) 2.1s,
    background-color var(--std-trans);
}

.main-page-section:first-of-type header h1:before {
  content: "welcome to";
  font-size: var(--article);
  position: absolute;
  top: 0;
  margin-top: -14px;
  font-weight: 300;
}

.main-page-section .main-page-text-container {
  margin-top: var(--size-30);
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 2;
}

.main-page-text-container-inner {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin: 0 auto;
  max-width: 75%;
  text-align: center;
}

.main-page-section article {
  padding-bottom: var(--size-30);
  flex: 0 0 100%;
  max-width: 100%;
}

.main-page-section article .main-page-text-container-paragraph {
  font-size: var(--article);
  max-width: 60%;
  font-weight: 500;
  margin: 0 auto;
}

.main-page-section article .main-page-text-container-paragraph h3 {
  padding-bottom: var(--size-12);
}

.main-page-section article .main-page-text-container-paragraph a {
  display: block;
  margin-bottom: var(--size-10);
  cursor: pointer;
}

.main-page-section article .main-page-text-container-paragraph a + h3 {
  margin-top: var(--size-20);
}

.main-page-section .main-page-cta-btn-link {
  display: flex;
  justify-content: center;
  max-width: fit-content;
  z-index: 3;
}

.main-page-section .main-page-cta-btn {
  display: inline-block;
  font-size: var(--article);
  padding: var(--size-12) calc(15px + (30 - 15) * (100vw - 320px) / 1120);
  letter-spacing: var(--medium-spacing);
  font-family: var(--main-font);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 600;
}

.main-page-section .main-page-cta-btn:hover {
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  cursor: pointer;
}
.main-page .main-page-section:last-of-type .main-page-content-container {
  justify-content: flex-end;
}

.main-page-section .main-page-content-container .main-page-google-maps {
  position: relative;
  z-index: -2;
}

.main-page-section
  .main-page-content-container
  .main-page-text-container
  .studio-single-socials {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  padding: var(--size-12) var(--size-12);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  font-size: var(--fs-xs);
  font-weight: 500;
  color: var(--off-white);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.main-page-section
  .main-page-content-container
  .main-page-text-container
  .studio-single-socials
  a {
  padding: var(--size-12);
}

.main-page-section
  .main-page-content-container
  .main-page-text-container
  .studio-single-socials
  svg {
  transform: rotate(90deg);
}

.main-page .main-page-section:last-of-type .angle-down {
  transform: rotate(0deg);
  animation: bounceUp 2s infinite;
}

.main-page .main-page-section:last-of-type .angle-down:hover {
  opacity: 0.6;
  animation-play-state: paused;
}

@media only screen and (max-width: 980px) {
  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container {
    position: static;
    margin: 0 auto;
    padding-right: 0;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container
    .studio-single-socials {
    height: 100%;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container
    .studio-single-socials
    a {
    padding-bottom: var(--size-12);
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-google-maps {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-google-maps:after {
    width: 100vw;
    left: 0;
  }
}

@media only screen and (max-width: 980px) and (orientation: landscape) {
  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container
    .studio-single-socials {
    height: 70%;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container-inner {
    max-width: fit-content;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container-inner
    .main-page-text-container-paragraph {
    display: flex;
    flex-direction: column;
    gap: var(--size-5);
  }

  .main-page-section
    .main-page-content-container
    .main-page-text-container-inner
    article {
    max-width: 93%;
  }

  .main-page-section h2 {
    font-size: var(--size-40);
  }

  .main-page-section article .main-page-text-container-paragraph {
    font-size: var(--size-12);
  }

  .main-page .main-page-section:last-of-type .angle-down {
    display: none;
  }

  .main-page-section .main-page-cta-btn {
    font-size: var(--size-12);
  }
}

@media only screen and (max-width: 767px) {
  .main-page-section .main-page-text-container {
    max-width: 100%;
  }

  .main-page-section:last-of-type
    .main-page-content-container
    .main-page-text-container
    .studio-single-socials {
    height: 50%;
  }
  .main-page-section .main-page-text-container article {
    max-width: 80%;
  }

  .main-page-section article .main-page-text-container-paragraph {
    max-width: 100%;
  }
}
