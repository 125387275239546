.book-studio-section {
  color: var(--faded-black);
  background: var(--white);
  padding-top: calc(100px + (180 - 100) * (100vw - 320px) / 1120);
}

.book-studio-section .book-studio-section-header {
  font-size: var(--h2);
  text-align: center;
  padding-bottom: var(--size-40);
}

.book-studio-section .studio-single-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-writing-mode: vertical-lr;
  writing-mode: vertical-lr;
  padding: var(--size-12) var(--size-12);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: var(--white);
  height: 50%;
  font-size: var(--fs-xs);
  font-weight: 500;
  color: var(--faded-black);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.book-studio-section .studio-single-socials svg {
  transform: rotate(90deg);
}

.book-studio-section .studio-single-socials-icons a {
  padding: var(--size-12);
}

@media only screen and (max-width: 980px) {
  .book-studio-section .studio-single-socials {
    height: 40%;
    z-index: 4;
  }
}

@media only screen and (max-width: 980px) and (orientation: landscape) {
  .book-studio-section .studio-single-socials {
    height: 60%;
  }
}
